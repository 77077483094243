/**
 * External Dependencies
 */
import 'jquery';
var Flickity = require('flickity');
import Plyr from 'plyr/dist/plyr';
import AOS from 'aos';

$(document).ready(function() {

  AOS.init({
    offset: 200,
    once: true,
  });

  // SLIDER
  $('.news-slider').each(function() {
    var carousel = new Flickity('.news-slider', {
      pageDots: false,
      cellAlign: 'left',
      initialIndex: 0,
      lazyLoad: true,
      draggable: false,
      wrapAround: false,
      freeScroll: false,
      prevNextButtons : false,
    });

    var previousButton = document.querySelector('.news .slider-button-prev');
    previousButton.addEventListener( 'click', function() {
      carousel.previous();
    });

    var nextButton = document.querySelector('.news .slider-button-next');
    nextButton.addEventListener( 'click', function() {
      carousel.next();
    });
  });

  // MODAL
  $('.button--video').click(function() {
    $('body').toggleClass('modal-open');
    $('#video').get(0).play();
  });

  $('.close-modal').click(function() {
    $('body').removeClass('modal-open');
    $('#video').get(0).pause();
  });

  // History slider
  $('.historyslider').each(function() {
    var count = $('.historyslider > div').length;
    var carousel = new Flickity('.historyslider', {
        pageDots: false,
        wrapAround: false,
        cellAlign: 'left',
        draggable: true,
        initialIndex: 0,
        freeScroll: false,
        imagesLoaded: true,
        prevNextButtons: false,
    });

    var resizeFlickity = $(window).width();

    $('.historyslider').on('dragStart.flickity', () => (document.ontouchmove = () => false));
    $('.historyslider').on('dragEnd.flickity', () => (document.ontouchmove = () => true));

    $(window).resize(function () {
      if (resizeFlickity !== $(window).width()) {
        carousel.resize();
        resizeFlickity = $(window).width();
      }
    });
  });

  $('.image-slider').each(function() {
    var carousel = new Flickity('.image-slider', {
      pageDots: false,
      cellAlign: 'left',
      initialIndex: 0,
      lazyLoad: true,
      draggable: true,
      wrapAround: true,
      freeScroll: false,
      prevNextButtons : false,
    });

    var previousButton = document.querySelector('.image-slider-button-prev');
    previousButton.addEventListener( 'click', function() {
      carousel.previous();
    });

    var nextButton = document.querySelector('.image-slider-button-next');
    nextButton.addEventListener( 'click', function() {
      carousel.next();
    });
  });

  // MENU CLICK
  $('nav .menu-item-has-children').on('click', function() {
    if($(this).hasClass('active')) {
      $('body').removeClass('submenu-visible');
      $("html").removeClass('overflow-hidden');
      $(this).removeClass('active');
      return;
    }
    $('body').addClass('submenu-visible');
    $("html").addClass('overflow-hidden');
    $(".menu-item-has-children").removeClass("active");

    $(this).addClass('active');
  });
  $('.menu-bg').on('click', function() {
    $('body').removeClass('submenu-visible');
    $("html").removeClass('overflow-hidden');
    $('.menu-item-has-children').removeClass('active');
  });

  // SUBMENUS
  $('nav .sub-menu').wrapInner('<div class="menu-wrapper"></div>');
  $('nav .sub-menu').wrapInner('<div class="submenu-wrapper"><div class="container flex flex-wrap"><div class="submenu-content"></div></div></div>');
  $('.menu-title').each(function(){
      var submenu = $(this).closest('li').find('.sub-menu .container .submenu-content');
      submenu.prepend( $(this) );
      $(this).removeClass('hidden');
  });
  $('.menu-description').each(function(){
      var submenu = $(this).closest('li').find('.sub-menu .container .submenu-content .menu-wrapper');
      submenu.prepend( $(this) );
      $(this).removeClass('hidden');
  });
  $('.menu-image').each(function(){
      var submenu = $(this).closest('li').find('.sub-menu');
      submenu.prepend( $(this) );
      $(this).removeClass('hidden');
  });

  // HAMBURGER SUBMENU
  $('.hamburger-nav .sub-menu').wrapInner('<div class="submenu-wrapper w-full mx-auto flex justify-between flex-wrap items-center"></div>');
  $('.hamburger-nav .menu-mobiel-menu-container li.menu-item-has-children').click(function() {
    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
    } else {
      $('.hamburger-nav .menu-mobiel-menu-container ul li.menu-item-has-children').removeClass('active');
      $(this).addClass('active');
    }
  });

  // FILTERS
  $('.cases-checkbox').change(handleCaseFilter);

  function handleCaseFilter () {

    var uristring = '?category=';

    $('.cases-checkbox:checked').each(function(index) {
      var isLastElement = index == $('.cases-checkbox:checked').length -1;

      uristring += $(this).val();

      if(!isLastElement) {
        uristring += ',';
      }

    });

    window.location.href = uristring;
  }

  // FILTERS
  $('.news-checkbox').change(handleNewsFilter);

  function handleNewsFilter () {

    var uristring = '?category=';

    $('.news-checkbox:checked').each(function(index) {
      var isLastElement = index == $('.news-checkbox:checked').length -1;

      uristring += $(this).val();

      if(!isLastElement) {
        uristring += ',';
      }

    });

    window.location.href = uristring;
  }

  // FILTERS
  $('.vacancies-checkbox').change(handleCatFilter);

  function handleCatFilter () {

    var uristring = '?category=';

    $('.vacancies-checkbox:checked').each(function(index) {
      var isLastElement = index == $('.vacancies-checkbox:checked').length -1;

      uristring += $(this).val();

      if(!isLastElement) {
        uristring += ',';
      }

    });

    window.location.href = uristring;
  }

  // SCROLLED
  $(window).on('scroll', function() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('scrolled');
    }
    else {
      $('body').removeClass('scrolled');
    }
  });

  // FILTER
  $('.filter').click(function() {
    $(this).toggleClass('active');
    $('body').toggleClass('filter-active');
    $('html').toggleClass('overflow-hidden');
  });

  $('.close-filter').click(function() {
    $(this).removeClass('active');
    $('body').removeClass('filter-active');
    $('html').removeClass('overflow-hidden');
  });

  // MENU
  $('.hamburger').click(function() {
    $(this).toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  $('.hamburger-nav-bg').click(function() {
    $('.hamburger').toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  // RANDOM WORD
  var words = $('.randomword').length;
  function fadeWords(nr) {
    if (nr == words) {
      fadeWords(0);
    } else {
      $('.randomword').eq(nr).addClass('active');
      setTimeout(function() {
        $('.randomword').eq(nr).removeClass('active');
        fadeWords(nr+1);
      }, 800);
    }
  }

  // RANDOM WORD
  if($('.randomword').length) {
    fadeWords(0);
  }

  // ANIMATE
  $.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  };
  $(window).on('resize scroll', function () {
    $('[data-animate]').each(function () {
      if ($(this).isInViewport()) {
        $(this).addClass('animate-scroll');
      } else {
        $(this).remove('animate-scroll');
      }
    });
  });
  $('[data-animate]').each(function () {
    if ($(this).isInViewport()) {
      $(this).addClass('animate-scroll');
    } else {
      $(this).remove('animate-scroll');
    }
  });

  // PLYR
  const players = Array.from(document.querySelectorAll('.plyr__video-embed'));

  players.map(player => new Plyr(player, {
    ratio: '16:9',
    controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume'],
    autopause: false,
    muted: false,
  }));

  // TABNAV
  if($('.js-tabnav-tab').length) {
    (function($) {
      $.fn.tabnav = function tabnav(userConfig) {
        var config = $.extend({
          activeTabClass: 'active',
          activePanelClass: 'active',
        }, userConfig);

        var $wrapper = $(this);
        var $tabs = $wrapper.find('.js-tabnav-tab');
        var $panels = $wrapper.find('.js-tabnav-panel');
        var $panelsWrapper = $wrapper.find('.js-tabnav-panels');
        var $activeTab;
        var $activePanel;
        var activeTab = 0;

        console.log($activeTab);
        console.log(activeTab);

        $panels
        .attr('role', 'tabpanel')
        .attr('aria-hidden', 'true');

        $tabs
        .attr('role', 'tab')
        .attr('aria-selected', 'false')
        .attr('aria-setsize', $tabs.length)
        .attr('tabindex', 0)
        .each(function(i, e) {
          var $elem = $(e);
          if ($elem.hasClass(config.activeTabClass)) {
            setActiveTab(i);
          }
          $elem.attr('aria-posinset', i + 1);
        })
        .click(setTabs)
        .keypress(setTabs);

        function setActiveTab(tabPos) {
          $activeTab = $($tabs.get(tabPos))
          .addClass(config.activeTabClass)
          .attr('aria-selected', 'true');

          $activePanel = $($panels.get(tabPos))
          .addClass(config.activePanelClass)
          .attr('aria-hidden', 'false');

          $panelsWrapper.css('height', $activePanel.height() + 'px');
        }

        function setTabs(e) {
          if (e.type === 'keypress' && e.keyCode !== 13) {
            return;
          }

          var $elem = $(e.currentTarget);

          var tabPos = Number($elem.attr('aria-posinset')) - 1;
          $tabs
          .removeClass(config.activeTabClass)
          .attr('aria-selected', 'false');
          $panels
          .removeClass(config.activePanelClass)
          .attr('aria-hidden', 'true');

          setActiveTab(tabPos);
        }

        $(window).resize(function onWindowResize() {
          $panelsWrapper.css('height', $activePanel.height() + 'px');
        });

        $(window).load(function() {
          $panelsWrapper.css('height', $activePanel.height() + 'px');
        });
      }

      var tabnav = $('.tabnav');
      if (tabnav.length != 0) {
        $(tabnav).each(function(){
          $(this).tabnav();
        });
      }
    }(jQuery));
  }

  // ACCORDEON
  $(function() {
    $('.accordeon > .accordeon-item.is-active').children('.accordeon-panel').slideDown();
    $('.accordeon > .accordeon-item').click(function() {
      $(this).siblings('.accordeon-item').removeClass('is-active').children('.accordeon-panel').slideUp();
      $(this).toggleClass('is-active').children('.accordeon-panel').slideToggle('ease-out');
    });
  });
});
